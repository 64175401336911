import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { css, Global } from "@emotion/react";
import { Box, useTheme } from "@mui/material";

import AdyenCardConnect from "~common/components/bank-linking/AdyenCardConnect";
import {
  setBankLinkingStep,
  setDisableAch,
} from "~common/store/slices/bankLinking-slice";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";

const PaymentLinking: React.VFC = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  usePaymentInstruments();

  useEffect(() => {
    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(setDisableAch(true));

    return () => {
      dispatch(setDisableAch(false));
    };
  }, [dispatch]);

  return (
    <Box height="100%" overflow="auto">
      <Global
        styles={css`
          body {
            background: ${palette.tertiary.main};
          }
        `}
      />

      <SmallPagePanel
        icon={<></>}
        title={<Box sx={{ fontSize: 26 }}>Link your debit card.</Box>}
        subtitle={
          <>
            The Catch card comes with a <strong>free digital debit card</strong>{" "}
            linked to your existing debit card, so you can earn everywhere.
          </>
        }
        sx={{
          pt: 12,
          pb: 40,
          "& > div > div > div > div:last-of-type": {
            background: palette.tertiary.main,
            p: 6,
            pt: {
              zero: 9,
              md: 6,
            },
            pb: 12,
            position: "fixed",
            bottom: 0,
            textAlign: "center",
            boxShadow: "0px -4px 16px 0px rgba(0, 0, 0, 0.50)",
          },
          "button.MuiButton-contained": {
            maxWidth: "var(--xsmall-page-panel-max-width)",
            boxShadow: "none",
          },
        }}
        bgcolor="tertiary.main"
      >
        <AdyenCardConnect inline mode="dark" appView />
      </SmallPagePanel>
    </Box>
  );
};

export default PaymentLinking;
